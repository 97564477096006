import axios from 'axios'
const basePath = process.env.REACT_APP_API

export default class ProposalResource {
  static fetCnpjconsignees() {
    return axios.get(`${basePath}/payrollloan/v1/cnpjconsignees?onlyExternal=true`, {})
  }

  static fecthPartnercompanies(id?: any) {
    return axios.get(`${basePath}/payrollloan/v1/partnercompanies/${id}`, {})
  }

  static fetchTerms() {
    return axios.get(`${basePath}/terms/v1/terms/PAYROLL_LOAN_REFERRAL`, {})
  }
}
