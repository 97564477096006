import styled, { css } from 'styled-components'

interface MaskedInputStylesProps {
  containerType?: boolean
  isFocused?: boolean
  isErrored?: boolean
}

export const Container = styled.div<MaskedInputStylesProps>`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  width: 100%;
  margin-right: 24px;
  margin-bottom: 16px;
  flex-direction: column;
  & + div {
    margin-right: 0px;
  }
  ${({ containerType }) =>
    !containerType &&
    css`
      padding: 8px 0;
    `}
`

export const CheckMarkContainer = styled.div`
  position: absolute;
  width: 2rem;
  height: 2rem;
  bottom: 17px;
  right: 10px;
`

export const RightContent = styled.div`
  position: absolute;
  width: 2rem;
  height: 2rem;
  bottom: 17px;
  right: 10px;
`
export const LoaderContent = styled.div`
  position: absolute;
  right: 10px;
  bottom: 14px;
  background-color: #ffffff;
`

export const Label = styled.label`
  display: inline-block;
  font-family: 'Clan Web Pro Medium';
  color: #555555;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 8px;
`

export const SubLabelText = styled.p`
  display: flex;
  justify-content: space-between;
  font-family: 'Clan Web Pro Medium';
  font-size: 12px;
  line-height: 22px;
  color: #8e8e8e;
  margin: 0 0 8px 0;
`

export const ComponentTooltip = styled.div`
  position: relative;
  display: inline-block;
  :hover {
    span {
      visibility: visible;
    }
  }
`

export const MessageTooltipText = styled.span`
  visibility: hidden;
  background: #101010;
  width: 196px;
  color: #ffffff;
  text-align: center;
  font-size: 11px;
  line-height: 16px;
  font-family: 'Clan Web Pro News';
  position: absolute;
  z-index: 1;
  box-shadow: 0px 34px 30px -20px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 8px 24px;
  bottom: 130%;
  left: 57%;
  margin-left: -104px;
  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: 0;
    border-width: 5px;
    border-style: solid;
    border-color: #101010 transparent transparent transparent;
    right: 88px;
  }
`

export const InputContainer = styled.div<MaskedInputStylesProps>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;

  input {
    font-size: 14px;
    padding: 10px 15px 5px 0;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    padding: 16px;

    width: 100%;
    color: #000;
    &:focus {
      outline: none;
    }
    &:disabled {
      border-bottom: 1px solid #e5e5e5 !important;
      background-color: #f6f6f6;
      color: #8e8e8e;
    }

    input:disabled {
      border-bottom: 2px solid #8e8e8e;
    }
    ${({ isErrored }) =>
      isErrored &&
      css`
        border-color: #db3939;
      `}
    ${({ isFocused }) =>
      isFocused &&
      css`
        border-color: #0090ff;
      `}
  }

  img {
    position: absolute;
    right: 8px;
    top: 20px;
    ${({ isErrored }) =>
      isErrored &&
      css`
        top: 15px;
      `}
  }
`

export const Error = styled.span`
  display: flex;
  color: #ff6b6b;
  font-family: 'Clan Web Pro Medium';
  padding: 4px 0;
  font-size: 12px;
`
