import React, { Children, useCallback } from 'react'
import { Container, Label, Field, Paragraph } from './styles'

interface CheckboxProps {
  label?: any
  name: string
  onChange: any
  checked: boolean
  disabled?: boolean
  value?: any
  children: any
}

const Checkbox = (props: CheckboxProps) => {
  const { name, label, checked, onChange, disabled = false, children, ...rest } = props

  const handleChange = useCallback(
    (e) => {
      if (!e.target.disabled) {
        onChange(e)
      }
    },
    [onChange],
  )

  return (
    <Container>
      <Field
        type="checkbox"
        id={name}
        name={name}
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
        {...rest}
      />
      <Label htmlFor={name} {...rest}>
        {label}
      </Label>
      <Paragraph>{children}</Paragraph>
    </Container>
  )
}

export default Checkbox
