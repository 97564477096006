import React, { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as Yup from 'yup'

import { InputContainer, Container, Error, Label, SubLabel } from './styles'

const schema = Yup.object().shape({
  name: Yup.string()
    .required('Insira nome e sobrenome')
    .matches(/^[A-Za-zÀ-ü]{2,}[\s]([A-Za-zÀ-ü']{2,}|[A-Za-zÀ-ü.']{2,}[\s]|[A-Za-zÀ-ü']{1,}[\s])[A-Za-zÀ-ü']*/gi, {
      message: 'O nome e/ou o sobrenome precisam ter 2 caracteres ou mais',
    })
    .matches(/^([^0-9]*)$/, {
      message: 'O nome não pode conter números',
    })
    .required('Nome obrigatório')
    .max(40, 'Este campo tem limite de 40 caracteres'),
  cpf: Yup.string().required('Cpf obrigatório'),
})

interface InputData {
  name?: any
  value?: any
  icon?: any
  isDisabled?: boolean
  label?: string
  containerType?: any
  norMarginLeft?: boolean
  validator?: any
  onBlur?: any
  subLabel?: string
  onChange?: any
  hasError?: any
  type?: string
  readonly?: boolean
}

type InputProps = JSX.IntrinsicElements['input'] & InputData

const Input: React.FC<InputProps> = ({
  /* eslint-disable */
  name,
  icon: Icon,
  isDisabled,
  label,
  subLabel,
  containerType,
  norMarginLeft,
  validator,
  onBlur: onBlurProps,
  onChange: onChangeProps,
  hasError,
  type = 'text',
  readonly = false,
  ...rest
}) => {
  const { register, errors, trigger } = useForm({
    resolver: yupResolver(schema),
  })

  const [isFocused, setIsFocused] = useState(false)

  const handleChange = (event: any) => {
    type === 'number' && (event.target.value = parseInt(event.target.value))
    event.stopPropagation()
    if (onChangeProps) onChangeProps(event.target)
  }

  const handleBlur = (event: any) => {
    event.persist()
    if (onBlurProps) onBlurProps(event)
    if (!readonly) trigger(name)
    setIsFocused(false)
  }

  useEffect(() => {
    if (!hasError) return
    hasError(name, !!errors[name])
  }, [errors[name]])

  return (
    <Container containerType={containerType}>
      <Label htmlFor={name} {...{ isFocused: isFocused, isDisabled: isDisabled }}>
        {label}
      </Label>

      {!!subLabel && <SubLabel>{subLabel}</SubLabel>}

      <InputContainer {...{ isErrored: !!errors[name], isFocused: !!isFocused }}>
        {errors[name] && (
          <Error className="error-form" title={errors[name].message}>
            {errors[name].message}
          </Error>
        )}
        <input
          autoComplete="nope"
          name={name}
          // onFocus={handleInputFocus}
          // defaultValue={defaultValue}
          onBlur={handleBlur}
          onChange={handleChange}
          ref={register}
          type={type}
          readOnly={readonly}
          {...rest}
        />
        {/* {error} */}
        {/* {!error && isFilled && !isFocused && containerType !== 'hidden' && (
          <img src={checkIconBlack} alt="Check icon" />
        )}
        {error && <img src={errorIcon} alt="Error icon" />} */}
      </InputContainer>
    </Container>
  )
}

export default Input
