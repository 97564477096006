import { Card } from 'components/UI/Card'
import React from 'react'

import { TitleConfirm, SubTitleConfirm, Container, Content } from './styles'

import { Button } from 'components/UI/Button'
const Doc = () => {
  return (
    <svg width="150" height="150" viewBox="0 0 201 200" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M134.5 66C128.665 66 123.069 63.6822 118.944 59.5564C114.818 55.4306 112.5 49.8348 112.5 44V34.231C112.5 33.8067 112.417 33.3865 112.254 32.9944C112.092 32.6023 111.854 32.2461 111.554 31.946C111.254 31.646 110.898 31.408 110.506 31.2457C110.114 31.0834 109.693 30.9999 109.269 31H75.5C69.6652 31 64.0695 33.3179 59.9437 37.4437C55.8179 41.5695 53.5 47.1652 53.5 53V149C53.5 154.835 55.8179 160.431 59.9437 164.556C64.0695 168.682 69.6652 171 75.5 171H125.5C131.335 171 136.931 168.682 141.056 164.556C145.182 160.431 147.5 154.835 147.5 149V69.231C147.5 68.8067 147.417 68.3865 147.254 67.9944C147.092 67.6023 146.854 67.2461 146.554 66.946C146.254 66.646 145.898 66.408 145.506 66.2457C145.114 66.0834 144.693 65.9999 144.269 66H134.5Z"
        stroke="#101010"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <path d="M111.522 31.915L146.531 66.969" stroke="#101010" strokeWidth="4" strokeLinecap="round" />
      <path
        d="M139.505 75.963V149.003C139.505 150.842 139.143 152.662 138.439 154.361C137.736 156.059 136.704 157.602 135.404 158.902C134.104 160.203 132.561 161.234 130.862 161.937C129.164 162.641 127.343 163.003 125.505 163.003H75.5049C71.7919 163.003 68.2309 161.528 65.6054 158.902C62.9799 156.277 61.5049 152.716 61.5049 149.003V53.003C61.5049 49.29 62.9799 45.729 65.6054 43.1035C68.2309 40.478 71.7919 39.003 75.5049 39.003H102.544C103.064 39.003 103.562 39.2095 103.93 39.577C104.298 39.9446 104.505 40.4431 104.505 40.963V44.003C104.505 51.9595 107.666 59.5901 113.292 65.2162C118.918 70.8423 126.548 74.003 134.505 74.003H137.544C138.064 74.003 138.562 74.2095 138.93 74.577C139.298 74.9446 139.505 75.4431 139.505 75.963Z"
        fill="#F3F3F3"
      />
      <path d="M93.5 71H77.5" stroke="#101010" strokeWidth="4" strokeLinecap="round" />
      <path d="M123.5 111H77.5" stroke="#101010" strokeWidth="4" strokeLinecap="round" />
      <path d="M123.5 131H77.5" stroke="#101010" strokeWidth="4" strokeLinecap="round" />
      <path d="M123.5 91H77.5" stroke="#101010" strokeWidth="4" strokeLinecap="round" />
      <rect x="136.5" y="76" width="48" height="48" rx="12" fill="#0090FF" />
      <path
        d="M168.891 94.5832L157.464 106.011L151.751 100.297"
        stroke="white"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const ArrowRight = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="#ffffff" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.7"
        d="M21.8263 21.5634L25.2354 16.5634C25.4671 16.2236 25.4671 15.7766 25.2354 15.4368L21.8263 10.4368C21.5152 9.98045 20.8931 9.86275 20.4368 10.1739C19.9805 10.485 19.8628 11.1071 20.1739 11.5634L23.15 16L20.1739 20.4368C19.8628 20.8931 19.9805 21.5152 20.4368 21.8263C20.8931 22.1374 21.5152 22.0197 21.8263 21.5634Z"
      />
      <path
        opacity="0.4"
        d="M15.8263 21.5634L19.2354 16.5634C19.4671 16.2236 19.4671 15.7766 19.2354 15.4368L15.8263 10.4368C15.5152 9.98045 14.8931 9.86275 14.4368 10.1739C13.9805 10.485 13.8628 11.1071 14.1739 11.5634L17.15 16L14.1739 20.4368C13.8628 20.8931 13.9805 21.5152 14.4368 21.8263C14.8931 22.1374 15.5152 22.0197 15.8263 21.5634Z"
      />
      <path
        opacity="0.2"
        d="M9.82634 21.5634L13.2354 16.5634C13.4671 16.2236 13.4671 15.7766 13.2354 15.4368L9.82634 10.4368C9.51522 9.98045 8.89309 9.86275 8.43678 10.1739C7.98047 10.485 7.86277 11.1071 8.17389 11.5634L11.15 16L8.17389 20.4368C7.86277 20.8931 7.98047 21.5152 8.43678 21.8263C8.89309 22.1374 9.51522 22.0197 9.82634 21.5634Z"
      />
      <path
        opacity="0.1"
        d="M3.82634 21.5634L7.23544 16.5634C7.46713 16.2236 7.46713 15.7766 7.23544 15.4368L3.82634 10.4368C3.51522 9.98045 2.89309 9.86275 2.43678 10.1739C1.98047 10.485 1.86277 11.1071 2.17389 11.5634L5.15 16L2.17389 20.4368C1.86277 20.8931 1.98047 21.5152 2.43678 21.8263C2.89309 22.1374 3.51522 22.0197 3.82634 21.5634Z"
      />
      <path d="M27.8263 21.5634L31.2354 16.5634C31.4671 16.2236 31.4671 15.7766 31.2354 15.4368L27.8263 10.4368C27.5152 9.98045 26.8931 9.86275 26.4368 10.1739C25.9805 10.485 25.8628 11.1071 26.1739 11.5634L29.15 16L26.1739 20.4368C25.8628 20.8931 25.9805 21.5152 26.4368 21.8263C26.8931 22.1374 27.5152 22.0197 27.8263 21.5634Z" />
    </svg>
  )
}

export const Step3: React.FC = () => {
  const refreshPage = () => {
    window.location.reload()
  }
  return (
    <Container>
      <Card>
        <Content>
          <Doc />
          <TitleConfirm>Dados recebidos com sucesso!</TitleConfirm>
          <SubTitleConfirm>Em breve entraremos em contato com o cliente.</SubTitleConfirm>
          <Button black big spaced onClick={refreshPage} iconRight={ArrowRight}>
            Enviar nova proposta
          </Button>
        </Content>
      </Card>
    </Container>
  )
}
