import { Indication } from '../models/Indication'

export const hasError = (currentStep: number): any => {
  if (currentStep === 1) {
    return {
      name: false,
      documentNumber: false,
      phoneNumber: false,
      convenant: false,
      establishmentCode: false,
      termsAndPrivacy: false,
      dataSharing: false,
    }
  } else if (currentStep === 2) {
    return {}
  } else if (currentStep === 3) {
    return {}
  }
}

export const hasFilled = (currentStep: number, data: Indication): any => {
  if (currentStep === 1) {
    return (
      data.name == null ||
      data.documentNumber == null ||
      !data.phoneNumber ||
      data.convenant == null ||
      data.convenant == '' ||
      data.establishmentCode == null ||
      !data.termsAndPrivacy ||
      !data.dataSharing
    )
  } else if (currentStep === 2) {
  } else if (currentStep === 3) {
    return {}
  }
}
