import styled from 'styled-components'

interface propsStep {
  active?: any
}

export const Container = styled.div`
  width: 354px;
  padding: 88px 64px 64px;
  background-color: #f6f6f6;
`
export const Content = styled.div`
  flex-direction: column;
`

export const TitleStep = styled.h1`
  color: #8e8e8e;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.01em;
  font-family: 'Clan Web Pro Medium';
  margin-bottom: 20px;
`

export const ContentStepBox = styled.div`
  position: relative;
  flex-direction: column;
`

export const Row = styled.div`
  position: absolute;
  left: 20px;
  height: 500px;
  border-left: 1px solid #e5e5e5;
  z-index: 1;
  height: 100%;
`

export const StepBox = styled.div<propsStep>`
  align-items: center;
  margin-left: 4px;
  z-index: 2;
  margin-bottom: 24px;
  &:nth-child(4) {
    margin-bottom: 0px;
  }
  .active {
    margin-left: 0px;
    width: 32px;
    height: 32px;
    padding: 4px 11px;
    background: var(--color-black-primary);
    color: var(--color-white-primary);
  }
  .active + p {
    margin-left: 22px;
    color: #101010;
  }
  p {
    color: #646464;
  }
`
export const NumberStep = styled.div`
  background: #e5e5e5;
  border-radius: 8px;
  width: 24px;
  height: 24px;
  padding: 0px 12px;
  color: #8e8e8e;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
`
export const StepName = styled.p`
  margin-left: 26px;
  color: var(--color-black-primary);
  letter-spacing: 0.01em;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Clan Web Pro Medium';
`
