import styled from 'styled-components'

export const Container = styled.div`
  justify-content: start;
  background: #ffffff;
  box-shadow: 0px -1px 0px rgba(0, 0, 0, 0.03);
  width: 100%;
  padding: 24px 30px 24px 67px;
  position: fixed;
  bottom: 0;
  z-index: 3;
`

export const ContainerButton = styled.div`
  width: 442px;
  max-width: 100%;
  > button {
    width: 50%;
  }
`
