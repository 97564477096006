import React, { ReactElement, useCallback } from 'react'
// STYLES
import { Container, Content, ContentStep } from './styles'
// COMPONENTS
import StepDisplay from './Steps/Steps'
import { Footer } from './Footer'
import { Steps } from '../../../components/UI/Steps'
// MODEL
import { Indication } from '../../../models/Indication'
// CONTEXT
import { useIndication } from '../../../hooks/AppContext'

const Form = (): ReactElement => {
  const {
    step,
    prevStepDisabled,
    stepLoading,
    nextStepDisabled,
    prevStep,
    nextStep,
    formOpened,
    setFormOpened,
  } = useIndication()
  const data = new Indication(formOpened)

  const stepsArray = [
    {
      label: 'Dados do cliente',
    },
    {
      label: 'Resumo da proposta',
    },
    {
      label: 'Confirmação',
    },
  ]
  const handleFormInput = useCallback(
    (e: any) => {
      if (e.name === 'Convenant') {
        setFormOpened({ ...data.setConvenant(e.label) })
      }
      if (e.target?.name === 'termsAndPrivacyPolicy') {
        setFormOpened({ ...data.setTerms(e.target?.checked) })
      }
      if (e.target?.name === 'dataSharing') {
        setFormOpened({ ...data.setDataSharing(e.target?.checked) })
      }
      setFormOpened({
        ...data,
        [e.name]: e.value,
      })
    },
    [data],
  )

  return (
    <>
      <Container>
        <Steps currentStep={step} steps={stepsArray} />
        <Content className={`${step === 3 ? 'removeBackground' : ''}`}>
          <ContentStep className={`${step === 3 ? 'changeConfirm' : ''}`}>
            <StepDisplay currentStep={step} onChange={handleFormInput} formValues={data} />
          </ContentStep>
          {step !== 3 ? (
            <Footer
              prevStep={prevStep}
              nextStep={nextStep}
              currentStep={step}
              nextStepDisabled={nextStepDisabled}
              prevStepDisabled={prevStepDisabled}
              loading={stepLoading}
            />
          ) : (
            ''
          )}
        </Content>
      </Container>
    </>
  )
}

export default Form
