import ProposalResource from '../resource/IndicationResource'

export default class ProposalService {
  static fetCnpjconsignees = async () => {
    return ProposalResource.fetCnpjconsignees()
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error
      })
  }

  static fecthPartnercompanies = async (id?: any) => {
    return ProposalResource.fecthPartnercompanies(id)
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error
      })
  }

  static fetchTerms = async () => {
    return ProposalResource.fetchTerms()
      .then((response) => {
        return response
      })
      .catch((error) => {
        return error
      })
  }
}
