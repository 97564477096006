import React from 'react'
import { Step1, Step2, Step3 } from './index'

const StepDisplay = ({ currentStep, formValues, onChange }: any) => {
  switch (currentStep) {
    case 1: // @ts-ignore
      return <Step1 onChange={onChange} formValues={formValues} />
    case 2: // @ts-ignore
      return <Step2 formValues={formValues} />
    case 3:
      return <Step3 />
    default:
      return null
  }
}

export default StepDisplay
