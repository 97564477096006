import React from 'react'
import { LogoSection, SideMenuContainer } from './style'
import SideMenuItem from './SideMenuItem'
import { Logo, Profile } from './svgs'

import { useHistory } from 'react-router-dom'

const SideMenu = () => {
  const history = useHistory()

  const items = [
    {
      title: 'Enviar indicação',
      active: true,
      icon: Profile,
      onClick: () => history.push('/'),
    },
  ]

  return (
    <SideMenuContainer>
      <div style={{ flexDirection: 'column' }}>
        <LogoSection>
          <Logo />
        </LogoSection>
        {items.map((it: any, idx) => {
          return <SideMenuItem key={idx} item={it} />
        })}
      </div>

      <div
        style={{
          flexDirection: 'column',
          paddingLeft: '16px',
          paddingRight: '20px',
        }}
      ></div>
    </SideMenuContainer>
  )
}

export default SideMenu
