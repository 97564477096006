import React, { useEffect, useState } from 'react'

import { Container, Content, TitleStep, ContentStepBox, Row, StepBox, NumberStep, StepName } from './styles'

const Steps = ({ steps, currentStep }: any) => {
  const [stepState, setStepState] = useState([])

  useEffect(() => {
    /*eslint-disable */
    let createSteps = steps.map((step, idx) => ({
      description: step.label,
      component: step.component,
      selected: idx <= currentStep - 1,
    }))
    setStepState(createSteps)
  }, [steps, currentStep])
  /* eslint-disable */
  return (
    <Container>
      <Content>
        <TitleStep>ETAPAS</TitleStep>
        <ContentStepBox>
          <Row></Row>
          {stepState.map(({ selected, description }, idx) => (
            <StepBox key={idx}>
              <NumberStep className={`${selected ? 'active' : 'disabled'}`}>{idx + 1}</NumberStep>
              <StepName>{description}</StepName>
            </StepBox>
          ))}
        </ContentStepBox>
      </Content>
    </Container>
  )
}

export default Steps
