import React from 'react'

import { StyledCard } from './styles'

interface CardSelectProps {
  hasMarginRight?: any
  noBorder?: boolean
}

const Card: React.FC<CardSelectProps> = ({ children, ...props }) => {
  return <StyledCard {...props}>{children}</StyledCard>
}

export default Card
