import React from 'react'

// Context
import { useIndication } from '../../../../hooks/AppContext'
// Components
import { Row, Col } from '../../../../components/GRID'
import { Card } from 'components/UI/Card'
// Styles
import {
  TitleStep,
  Paginator,
  TitleResume,
  Circle,
  ContentData,
  ContentHeader,
  DataTitle,
  DataSubTitle,
  DataPerson,
  BoxDataPerson,
  BoxTitle,
  BoxData,
  ChangeData,
  TextChange,
} from './styles'

const Pencil = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.0003 5.24081C20.001 5.1092 19.9758 4.97874 19.926 4.8569C19.8763 4.73507 19.8029 4.62425 19.7103 4.53081L15.4703 0.29081C15.3768 0.198129 15.266 0.124804 15.1442 0.0750389C15.0223 0.0252743 14.8919 4.92783e-05 14.7603 0.000810171C14.6287 4.92783e-05 14.4982 0.0252743 14.3764 0.0750389C14.2545 0.124804 14.1437 0.198129 14.0503 0.29081L11.2203 3.12081L0.290261 14.0508C0.197579 14.1442 0.124254 14.2551 0.0744896 14.3769C0.024725 14.4987 -0.000500038 14.6292 0.000260855 14.7608V19.0008C0.000260855 19.266 0.105618 19.5204 0.293154 19.7079C0.48069 19.8955 0.735044 20.0008 1.00026 20.0008H5.24026C5.38019 20.0084 5.52015 19.9865 5.65108 19.9366C5.78201 19.8866 5.90097 19.8097 6.00026 19.7108L16.8703 8.78081L19.7103 6.00081C19.8015 5.90389 19.8759 5.79234 19.9303 5.67081C19.9399 5.5911 19.9399 5.51052 19.9303 5.43081C19.9349 5.38426 19.9349 5.33736 19.9303 5.29081L20.0003 5.24081ZM4.83026 18.0008H2.00026V15.1708L11.9303 5.24081L14.7603 8.07081L4.83026 18.0008ZM16.1703 6.66081L13.3403 3.83081L14.7603 2.42081L17.5803 5.24081L16.1703 6.66081Z"
        fill="#0090FF"
      />
    </svg>
  )
}

export const Step2: React.FC = ({ formValues }: any) => {
  const { setNextStepDisabled, setStep } = useIndication()
  const handlePrevstep = () => {
    setStep((previousStep: any) => previousStep - 1)
  }

  return (
    <Row>
      <Col sm={18} md={18} lg={18}>
        <Paginator>2/3</Paginator>
        <TitleStep>Fale um pouco mais sobre quem tem interesse no empréstimo</TitleStep>
        <Col sm={18} md={18} lg={18}>
          <Card>
            <div style={{ alignItems: 'center' }}>
              <Circle></Circle>
              <TitleResume>Dados do cliente</TitleResume>
            </div>
            <ContentData>
              <ContentHeader>
                <DataTitle>{formValues.name}</DataTitle>
                <DataSubTitle>Cliente interesado</DataSubTitle>
              </ContentHeader>

              <DataPerson>
                <BoxDataPerson>
                  <BoxTitle>Celular</BoxTitle>
                  <BoxData>{formValues.phoneNumber}</BoxData>
                </BoxDataPerson>
                <BoxDataPerson>
                  <BoxTitle>CPF</BoxTitle>
                  <BoxData>{formValues.documentNumber}</BoxData>
                </BoxDataPerson>
              </DataPerson>
            </ContentData>
          </Card>
          <ChangeData onClick={handlePrevstep}>
            <Pencil />
            <TextChange>Corrigir dados digitados</TextChange>
          </ChangeData>
        </Col>
      </Col>
    </Row>
  )
}
