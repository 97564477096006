import IndicationInterface from '../interfaces/Indication'

export class Indication {
  public name: string
  /* eslint-disable */
  public documentNumber: Number
  public phoneNumber: string
  public convenant: any
  public establishmentCode: string
  public termsAndPrivacy: any
  public dataSharing: any

  constructor(data: IndicationInterface) {
    this.name = data?.name
    this.documentNumber = data?.documentNumber
    this.phoneNumber = data?.phoneNumber
    this.convenant = data?.convenant
    this.establishmentCode = data?.establishmentCode
    this.termsAndPrivacy = data?.termsAndPrivacy
    this.dataSharing = data?.dataSharing

    return this
  }

  setConvenant(value: string) {
    this.convenant = value
    return this
  }
  setTerms(value: string) {
    this.termsAndPrivacy = value
    return this
  }
  setDataSharing(value: string) {
    this.dataSharing = value
    return this
  }
}
