import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
`

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: start;
  align-items: flex-start;
  background: #ffff;
  flex: 1;
  height: 100vh;
  overflow-y: auto;
  &.removeBackground {
    background: #f6f6f6;
  }
`

export const ContentStep = styled.div`
  padding: 88px 30px 0px 67px;
  width: 100%;
  margin-bottom: 188px;
  &.changeConfirm {
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: 0px;
    height: 100%;
  }

  /* step 1 */
  .loanList {
    flex-direction: column;
    > div {
      margin-right: 16px;
    }
  }
`

export const ContentLabel = styled.div`
  margin-bottom: 16px;
  & > div {
    width: 100%;
  }
`
