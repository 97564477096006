import React, { useCallback } from 'react'
import { Container, Content } from './styles'

interface ComponentProps {
  primary?: boolean
  black?: boolean
  white?: boolean
  color?: string
  small?: boolean
  large?: boolean
  full?: boolean
  uploader?: boolean
  big?: boolean
  transparent?: boolean
  spaced?: boolean
  outline?: boolean
  className?: string
  disabled?: boolean
  centered?: boolean
  fixedWidth?: boolean
  iconRight?: React.ComponentType
  iconLeft?: React.ComponentType
  onClick: any
  children: any
  width?: any
}

const Button: React.FC<ComponentProps> = ({
  iconRight: IconRight,
  iconLeft: IconLeft,
  children,
  disabled,
  onClick,
  ...props
}: ComponentProps) => {
  const handleClick = useCallback(
    (e: any) => {
      const isDisabled = e.target.closest('button').disabled
      if (!isDisabled) {
        onClick(e)
      }
    },
    [onClick],
  )

  return (
    <Container type="button" disabled={disabled} onClick={handleClick} {...props}>
      <Content {...props} iconRight={IconRight} iconLeft={IconLeft}>
        {IconLeft && <IconLeft />}
        {children}
        {IconRight && <IconRight />}
      </Content>
    </Container>
  )
}

export default Button
