import styled from 'styled-components'

export const TitleStep = styled.h3`
  margin: 0;
  font-family: 'Clan Web Pro News';
  font-size: 28px;
  line-height: 40px;
  margin: 0 0 72px 0;
`
export const Paginator = styled.p`
  color: #8e8e8e;
  font-size: 12px;
  margin: 0 0 8px;
  font-family: 'Clan Web Pro Medium';
`
export const Link = styled.a`
  color: var(--color-blue-primary);
  margin-left: 4px;
  :first-child {
    margin-right: 4px;
  }
`
export const TitleResume = styled.h2`
  display: flex;
  font-size: 11px;
  line-height: 16px;
  color: #8a8d8f;
  letter-spacing: 0.01em;
  text-transform: uppercase;
`
export const Circle = styled.div`
  width: 8px;
  height: 8px;
  background: #0090ff;
  border-radius: 4px;
  margin-right: 8px;
`

export const ContentData = styled.div`
  flex-direction: column;
`
export const ContentHeader = styled.div`
  flex-direction: column;
  margin-top: 40px;
`

export const DataTitle = styled.h4`
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: var(--color-black-primary);
  margin-bottom: 4px;
`
export const DataSubTitle = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: var(--color-black-primary);
  font-family: 'Clan Web Pro News';
`
export const DataPerson = styled.div`
  margin-top: 36px;

  > div:first-child {
    margin-right: 32px;
  }
`

export const BoxDataPerson = styled.div`
  flex-direction: column;
`
export const BoxTitle = styled.h4`
  font-size: 12px;
  line-height: 16px;
  color: #8a8d8f;
  font-family: 'Clan Web Pro Medium';
  margin-bottom: 4px;
`
export const BoxData = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: var(--color-black-primary);
  font-family: 'Clan Web Pro News';
`

export const ChangeData = styled.button`
  margin-top: 32px;
  border: navajowhite;
  background: inherit;
  display: flex;
`
export const TextChange = styled.div`
  color: #0090ff;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Clan Web Pro Medium';
  margin-left: 10px;
`

// Confirm

export const Container = styled.div`
  flex: 1;
  > div {
    height: 100%;
    justify-content: center;
    margin: 0px;
  }
`
export const Content = styled.div`
  flex-direction: column;
  align-items: center;
`

export const TitleConfirm = styled.h2`
  font-size: 32px;
  line-height: 40px;
  font-family: 'Clan Web Pro Medium';
  margin-top: 60px;
  margin-bottom: 24px;
  color: var(--color-black-primary);
`
export const SubTitleConfirm = styled.p`
  font-size: 24px;
  line-height: 40px;
  font-family: 'Clan Web Pro News';
  margin-bottom: 60px;
  color: var(--color-black-primary);
`
