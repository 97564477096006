import axios from 'axios'

axios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.message === 'Network Error' && !error.response) {
      console.log('Network error')
    }

    const status = error.response ? error.response.status : 400

    switch (status) {
      case 401:
        console.log('Error 401, Unauthorized')
        break
      case 404:
        console.log('Error 404, Not found')
        break
      case 403:
        console.log('Error 403, Forbidden  server refuses to run ')
        break
      case 500:
        console.log('Error 500, Server error - check the terminal for more info')
        break
      default:
        return Promise.reject(error)
    }
  },
)

export default axios
