import React from 'react'
import { Switch } from 'react-router-dom'

import Route from './Route'
import { SideMenu } from '../components/UI/SideMenu'
import { Recommendation } from '../screens/Recommendation'

import { ContainerRouters, ContentRouters } from './styles'

const Routes: React.FC = () => {
  return (
    <ContainerRouters>
      <SideMenu />
      <ContentRouters>
        <Switch>
          <Route path="/" component={Recommendation} />
        </Switch>
      </ContentRouters>
    </ContainerRouters>
  )
}

export default Routes
