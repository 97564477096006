import styled from 'styled-components'

export const StyledCard = styled.div<{ noBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: white;
  color: black;
  padding: 24px;
  font-family: 'Nunito', sans-serif;
  overflow: hidden;
  border: ${({ noBorder }) => (noBorder ? 'none' : '1px solid #e5e5e5')};
  padding: 40px;
  flex-grow: 1;
  margin: ${(props: any) =>
    !!props.simulationResume ? '7px 0px 7px 0px' : props.hasMarginRight ? '7px 15px 7px 0px' : '7px 0px 7px 0px'};

  @media (max-width: 1800px) {
    margin: 7px 0px 7px 0;
  }

  @media only screen and (max-width: 1279px) {
    width: 70%;
    .costs {
      margin-right: 50px !important;
    }
  }
`
