import React, { ReactElement } from 'react'
import { Button } from '../../../../components/UI/Button'
import { Container, ContainerButton } from './styles'
import { ArrowRight, ArrowLeft } from './svgs'

// CONTEXT
//import { IndicationContext } from '../../../../hooks/AppContext'

const Footer = (props: any): ReactElement => {
  const {
    currentStep,
    prevStep,
    nextStep,
    nextStepDisabled = false,
    prevStepDisabled = false,
    nextDescription = 'Próximo',
    loading = false,
  }: any = props

  return (
    <Container>
      <ContainerButton>
        {currentStep !== 1 ? (
          <Button white big spaced onClick={prevStep} iconLeft={ArrowLeft} {...{ disabled: prevStepDisabled || '' }}>
            Voltar
          </Button>
        ) : null}

        <Button
          black
          big
          spaced
          onClick={currentStep === 3 ? '' : nextStep}
          iconRight={ArrowRight}
          {...{ disabled: nextStepDisabled || loading }}
        >
          {loading ? 'Carregando...' : nextDescription}
        </Button>
      </ContainerButton>
    </Container>
  )
}

export default Footer
