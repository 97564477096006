import styled from 'styled-components'
import media from 'styled-media-query'

interface ColProps {
  sm: number
  md: number
  lg: number
  justifyContent?: string
  overflowy?: string
}

function getWidthColumn(value: any) {
  return `width: ${(value / 18) * 100}%`
}

const Col = styled.div<ColProps>`
  float: left;
  display: block;
  min-height: 1px;
  box-sizing: border-box;

  ${({ justifyContent }) =>
    justifyContent != null &&
    `
      display: flex;
      justify-content: ${justifyContent};
  `};

  ${({ overflowy }) =>
    overflowy != null &&
    `
      overflow-y: auto;
      max-height: 90vh;
      overflow-y: ${overflowy};
  `};

  ${media.lessThan<ColProps>('medium')`
    ${({ sm }) => sm && getWidthColumn(sm)};
  `}

  ${media.between<ColProps>('medium', 'large')`
    ${({ md }) => md && getWidthColumn(md)};
  `}

  ${media.greaterThan<ColProps>('large')`
    ${({ lg }) => lg && getWidthColumn(lg)};
  `}
`

export default Col
