import React, { createContext, useContext, useState } from 'react'
// MODEL
import { Indication } from '../models/Indication'
// SERVICES
import IndicationService from '../services/IndicationService'
import qs from 'querystring'
// import { useToast } from './ToastContext'

export const IndicationContext = createContext({})
export const useIndication = (): any => useContext(IndicationContext)

const IndicationProvider = ({ children }: any) => {
  // const { addToast } = useToast()
  const [optionsConvenant, setOptionsConvenant] = useState([])
  const [externalPartnercompanies, setExternalPartnercompanies] = useState('')
  const [stepLoading, setStepLoding] = useState(false)
  const [step, setStep] = useState(1)
  const [prevStepDisabled, setPrevStepDisabled] = useState(false)
  const [nextStepDisabled, setNextStepDisabled] = useState(false)
  // @ts-ignore
  const [formOpened, setFormOpened] = useState(new Indication())

  // Formatação first_name laste_name
  const name = formOpened.name
  const first_name = name?.split(' ')[0]
  const last_name = name?.substring(first_name.length).trim()

  const dataFormatedSF = [
    {
      oid: `${process.env.REACT_APP_OID}`,
      retURL: 'https://indicacoesbariconsig.com.br',
      lead_source: 'Indicações Consignado',
      [`${process.env.REACT_APP_WEBSITE}`]: 'Indicações Consignado',
      [`${process.env.REACT_APP_COMPANYNAME}`]: 'Banco Rendimento',
      first_name: first_name,
      last_name: last_name,
      [`${process.env.REACT_APP_CONVENANT}`]: formOpened.convenant,
      [`${process.env.REACT_APP_CPF}`]: formOpened.documentNumber,
      [`${process.env.REACT_APP_ESTABLISHMENTCODE}`]: formOpened.establishmentCode,
      mobile: formOpened.phoneNumber?.replace(/[()\ \s-]+/g, ''),
    },
  ]

  async function postData(url = '', data = dataFormatedSF[0]) {
    return await fetch(url, {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: qs.stringify(data),
    })
  }

  const prevStep = () => {
    setStep((previousStep: any) => previousStep - 1)
  }

  const nextStep = async (e: any) => {
    e.preventDefault()

    if (step === 1) {
      setStepLoding(true)
      try {
        const fetchData = await IndicationService.fecthPartnercompanies(formOpened.establishmentCode)

        if (fetchData === undefined || fetchData.status !== 200) {
          // addToast({
          //   type: 'error',
          //   title: 'Erro ao cadastrar proposta!',
          //   description: 'Confira os dados digitados e tente novamente.',
          // })
          setExternalPartnercompanies('Confira o código digitado e tente novamente.')
          setStepLoding(false)
        } else {
          setStep((previousStep: any) => previousStep + 1)
          setStepLoding(false)
        }
      } catch (error) {
        console.log(error)
      }
    }
    if (step === 2) {
      try {
        await postData(process.env.REACT_APP_URL)
          .then((data) => {
            setStep((previousStep: any) => previousStep + 1)
          })
          .catch((err) => console.log(err))
      } catch (error) {}
    }
    if (step === 3) {
      setStep((previousStep: any) => previousStep + 1)
    }
  }

  const values = {
    step,
    setStep,
    nextStep,
    prevStep,
    prevStepDisabled,
    setPrevStepDisabled,
    setStepLoding,
    stepLoading,
    nextStepDisabled,
    setNextStepDisabled,
    formOpened,
    setFormOpened,
    externalPartnercompanies,
    setExternalPartnercompanies,
    optionsConvenant,
    setOptionsConvenant,
  }

  return <IndicationContext.Provider value={{ ...values }}>{children}</IndicationContext.Provider>
}

export { IndicationProvider }
