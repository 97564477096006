import React, { useState, useEffect, useCallback } from 'react'
// Util
import { hasError, hasFilled } from '../../../../utils/fieldsValidation'
// Coponents
import { Row, Col } from '../../../../components/GRID'
import { Input } from '../../../../components/UI/Input'
import { MaskedInput } from '../../../../components/UI/MaskedInput'
import { Select } from '../../../../components/UI/Select'
import { Checkbox } from 'components/UI/Checkbox'
// Style
import { TitleStep, Paginator, Link } from './styles'
import { fullDateFormat } from '../../../../utils/formatDate'

// Service
import IndicationService from '../../../../services/IndicationService'
import { useIndication } from '../../../../hooks/AppContext'

export const Step1: React.FC = ({ onChange, formValues }: any) => {
  const {
    optionsConvenant,
    setOptionsConvenant,
    setNextStepDisabled,
    externalPartnercompanies,
    setExternalPartnercompanies,
  } = useIndication()

  const [url, setUrl] = useState<any>(null)
  const [validationError, setValidationError] = useState(hasError(1))

  const [checked, setChecked] = useState(false)
  const [checkedDataSharing, setDataSharing] = useState(false)
  const [defaultValueConvenant] = useState(optionsConvenant.find((val: any) => val.label === formValues.convenant))

  const handleReturnError = useCallback(
    (name: string, err: string) => {
      setValidationError({
        ...validationError,
        [name]: err,
      })
    },
    [validationError],
  )

  const handleChange = useCallback(
    (e: any) => {
      onChange(e)
      setExternalPartnercompanies(null)
    },
    [onChange],
  )

  const handleConvenant = useCallback(
    (field: any) => {
      onChange(field)
    },
    [formValues],
  )
  const handleChangeTerms = useCallback(
    (e: any) => {
      setChecked(e.target?.checked)
      onChange(e)
    },
    [formValues],
  )

  const handleChangeDataSharing = useCallback(
    (e: any) => {
      setDataSharing(e.target.checked)
      onChange(e)
    },
    [formValues],
  )

  const handleFecthTerms = useCallback(async () => {
    const fetchTerms = await IndicationService.fetchTerms()
    const { data } = await fetchTerms
    setUrl(data?.url)
  }, [])

  const handleFetchCnpjconsignees = useCallback(async () => {
    const fetchOptionsCnpjconsignees = await IndicationService.fetCnpjconsignees()
    const { data } = fetchOptionsCnpjconsignees

    const options = data?.map((item: any) => ({
      value: item.id,
      label: item.description,
      name: 'Convenant',
    }))
    setOptionsConvenant(options)
  }, [])

  useEffect(() => {
    handleFetchCnpjconsignees()
  }, [handleFetchCnpjconsignees])

  useEffect(() => {
    handleFecthTerms()
  }, [handleFecthTerms])

  useEffect(() => {
    const invalid = hasFilled(1, formValues) || !(Object.values(validationError).find((el) => !!el) === undefined)
    setNextStepDisabled(invalid)
  }, [validationError, formValues])

  return (
    <Row>
      <Col sm={18} md={18} lg={18}>
        <Paginator>1/3</Paginator>
        <TitleStep>Fale um pouco mais sobre quem tem interesse no empréstimo</TitleStep>
        <Col sm={18} md={12} lg={12}>
          <div style={{ flexDirection: 'column' }}>
            <div>
              <Input
                id="name"
                name="name"
                label="Nome Completo"
                type="text"
                value={formValues.name}
                onChange={handleChange}
                placeholder="Nome completo do cliente"
                hasError={handleReturnError}
              />
            </div>
            <div style={{ alignItems: 'end' }}>
              <MaskedInput
                type="text"
                id="documentNumber"
                name="documentNumber"
                label="CPF do cliente"
                value={formValues.documentNumber}
                onChange={handleChange}
                mask={'999.999.999-99'}
                placeholder={'000.000.000-00'}
                hasError={handleReturnError}
              />
              <MaskedInput
                id="phoneNumber"
                name="phoneNumber"
                label="Celular"
                value={formValues.phoneNumber}
                type="text"
                mask="(99) 99999-9999"
                placeholder="(00) 90000 0000"
                onChange={handleChange}
                hasError={handleReturnError}
              />
            </div>

            <Select
              id="Convenant"
              name="Convenant"
              label="Convênio"
              placeholder="Selecione o convênio do cliente"
              options={optionsConvenant}
              defaultValue={defaultValueConvenant}
              onChange={handleConvenant}
              hasError={handleReturnError}
            />
            <MaskedInput
              id="establishmentCode"
              name="establishmentCode"
              label="Código do estabelecimento"
              value={formValues.establishmentCode}
              type="text"
              onChange={handleChange}
              placeholder="Digite seu código de parceiro"
              mask="9999999"
              hasError={handleReturnError}
              externalError={externalPartnercompanies}
            />
            <Checkbox
              name="termsAndPrivacyPolicy"
              checked={checked || formValues.termsAndPrivacy}
              disabled={false}
              onChange={handleChangeTerms}
            >
              Li e aceito com os
              <Link href={url} target="_blank">
                termos de uso
              </Link>
              e
              <Link href={url} target="_blank">
                políticas de privacidade
              </Link>
              .
            </Checkbox>
            <Checkbox
              name="dataSharing"
              checked={checkedDataSharing || formValues.dataSharing}
              disabled={false}
              onChange={handleChangeDataSharing}
            >
              Meu cliente concorda com o compartilhamento de dados
            </Checkbox>
          </div>
        </Col>
      </Col>
    </Row>
  )
}
