import React from 'react'

import { IndicationProvider } from './AppContext'
import { ToastProvider } from './ToastContext'

const AppProvider: React.FC = ({ children }) => (
  <IndicationProvider>
    <ToastProvider>{children}</ToastProvider>
  </IndicationProvider>
)

export { AppProvider }
