import React from 'react'
// Lib
import { BrowserRouter } from 'react-router-dom'
// Routes page
import Routes from './routes'
// Interceptor
import './interceptors/axios.interceptor'
// CONTEXT
import { AppProvider } from './hooks'

// Style
import { GlobalStyle } from './components/UI/GlobalStyle'
import { ContainerGlobal } from './styles'
const App: React.FC = () => (
  <>
    <BrowserRouter forceRefresh={true}>
      <ContainerGlobal>
        <AppProvider>
          <Routes />
        </AppProvider>
      </ContainerGlobal>
    </BrowserRouter>
    <GlobalStyle />
  </>
)

export default App
