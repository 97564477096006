import styled, { css } from 'styled-components'

export const Container: any = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 16px;
  & + div {
    margin-left: 24px;
  }
  ${(props: any) =>
    !props.norMarginLeft &&
    css`
      margin-left: 0px !important;
    `}
  ${(props: any) =>
    !props.containerType &&
    css`
      padding: 8px 0;
    `}
`

export const Label = styled.label`
  display: inline-block;
  font-family: 'Clan Web Pro Medium';
  color: ${(props: any) => (!props.isFilled ? '#555555' : '#000')};
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 8px;
`

export const SubLabel = styled.p`
  font-size: 12px;
  line-height: 22px;
  color: #8e8e8e;
  font-family: 'Clan Web Pro Medium';
  margin: 0 0 8px 0;
`

export const InputContainer: any = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;

  input {
    font-size: 14px;
    padding: 10px 15px 5px 0;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    padding: 16px;
    width: 100%;
    color: #000;
    &:focus {
      outline: none;
    }
    &:disabled {
      border-bottom: 1px solid #e5e5e5 !important;
      background-color: #f6f6f6;
      color: #8e8e8e;
    }
    ${(props: any) =>
      props.isFocused &&
      css`
        border-color: #0090ff;
      `}

    ${(props: any) =>
      props.isErrored &&
      css`
        border-color: #db3939;
      `}
  }

  input:disabled {
    border-bottom: 2px solid #8e8e8e;
  }

  img {
    position: absolute;
    right: 8px;
    top: 20px;
    ${(props: any) =>
      props.isErrored &&
      css`
        top: 15px;
      `}
  }
`

export const Error = styled.span`
  display: flex;
  color: #ff6b6b;
  font-family: 'Clan Web Pro Medium';
  padding: 4px 0;
  font-size: 12px;
`
